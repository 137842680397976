import classNames from "classnames/bind";
import React, { useState } from "react";

import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const SearchBox = ({
  options,
  optionIcon,
  onSelect,
  placeholder,
}) => {
  const searchInput = React.createRef();
  const [filterText, setFilterText] = useState("");
  const [resultsVisibility, seResultsVisibility] = useState(false);

  const onClear = () => {
    setFilterText("")
    searchInput.current.value = "";
    searchInput.current.focus();
  }
  const onFocus = () => {
    seResultsVisibility(true);
  }
  const onBlur = () => {
    seResultsVisibility(false);
  }
  const onKeyUp = (event) => {
    const inputText = event.target.value;

    setFilterText(inputText);
  }

  return (
    <div className={cx("search-box")}>
      <div className={cx("search-input-container")}>        
        <input 
          className={cx("search-input")}
          ref={searchInput}
          type="text" 
          autoComplete="off" 
          placeholder={placeholder}
          onFocus={() => onFocus()} 
          onBlur={() => onBlur()} 
          onKeyUp={(e) => onKeyUp(e)} 
        />
        <div className={cx("clear-search")} onClick={() => onClear()}>X</div>
      </div>
      <ul className={cx("search-results")} style={{opacity: resultsVisibility ? 1 : 0, visibility: resultsVisibility ? "visible": "hidden"}}>          
          { !options.some((option) => option.label.toUpperCase().includes(filterText.toUpperCase())) && <div className={cx("no-results")}>No results</div>}          
          { options.map((option) => 
            <li className={cx("option")} key={option.value} onClick={() => onSelect(option)} style={{display: option.label.toUpperCase().includes(filterText.toUpperCase()) ? "" : "none"}}>
              <div className={cx("label")}>{option.label}</div>
              {optionIcon}
            </li>
          )}
        </ul>
    </div>
  );
};
export default SearchBox;
