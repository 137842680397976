import React from "react";

import { fetchData } from "../../utils/request-helper";
import Dashboard from "../../views/dashboard-lite/dashboard";

class DashboardLite extends React.Component {
  async componentDidMount() {
    this.updateGeneralData();
    this.updateAlertsData();
    this.updateAllCustomersTopicsData();
    this.updateNonPurchasersTopicsData();
    this.updateEmployeeRecognitionData();
  }

  async updateAlertsData() {
    const alertsData = await fetchData(
      `/dashboard_lite_alerts_data?${this.props.filtersQueryString}`
    );

    this.setState({ alertsData });
  }

  async updateAllCustomersTopicsData() {
    const allCustomersTopicsData = await fetchData(
      `/dashboard_lite_topics_data?${this.props.filtersQueryString}`
    );

    this.setState({ allCustomersTopicsData });
  }

  async updateEmployeeRecognitionData() {
    const { filterEmployeeRecognitionQuestion } = this.props;

    if (filterEmployeeRecognitionQuestion) {
      const employeeRecognitionData = await fetchData(
        `/dashboard_lite_employee_recognition_data?${this.props.filtersQueryString}`
      );

      this.setState({ employeeRecognitionData });
    }
  }

  async updateGeneralData() {
    const generalData = await fetchData(
      `/dashboard_lite_general_data?${this.props.filtersQueryString}`
    );

    this.setState({ generalData });
  }

  async updateNonPurchasersTopicsData() {
    const { filterPurchaserQuestion } = this.props;
    if (filterPurchaserQuestion) {
      const purchaserFilterKey = `fltrpurc_${filterPurchaserQuestion.id}`;
      const nonPurchasersTopicsData = await fetchData(
        `/dashboard_lite_topics_data?${this.props.filtersQueryString}&${purchaserFilterKey}=no`
      );

      this.setState({ nonPurchasersTopicsData });
    }
  }

  render() {
    const {
      alertsAccess,
      featuredQuestions = [],
      filterEmployeeRecognitionQuestion,
      filterPocOptions = [],
      filterPurchaserQuestion,
      filters = {},
      filtersQueryString = "",
      survey = {},
      who = {},
    } = this.props;
    const {
      alertsData,
      allCustomersTopicsData,
      employeeRecognitionData,
      generalData,
      nonPurchasersTopicsData,
    } = this.state || {};

    return (
      <Dashboard
        alertsAccess={alertsAccess}
        alertsData={alertsData}
        allCustomersTopicsData={allCustomersTopicsData}
        employeeRecognitionData={employeeRecognitionData}
        featuredQuestions={featuredQuestions}
        filterEmployeeRecognitionQuestion={filterEmployeeRecognitionQuestion}
        filterPocOptions={filterPocOptions}
        filterPurchaserQuestion={filterPurchaserQuestion}
        filters={filters}
        filtersQueryString={filtersQueryString}
        generalData={generalData}
        nonPurchasersTopicsData={nonPurchasersTopicsData}
        survey={survey}
        who={who}
      />
    );
  }
}

export default DashboardLite;
