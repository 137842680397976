import classNames from "classnames/bind";
import I18n from "i18n-js";
import React from "react";

import AlertsTile from "./alertsTile";
import Header from "./header";
import Insight from "./insight";
import InitiationsTile from "./initiationsTile";
import NpsTile from "./npsTile";
import SatTile from "./satTile";
import GoogleReviewsTile from "./googleReviewsTile";
import SaveOurSalesTile from "./saveOurSalesTile";
import { ErrorStatus } from "../shared";
import styles from "./styles.module.scss";
import FeaturedQuestion from "./featuredQuestion";

const cx = classNames.bind(styles);

const Dashboard = ({
  alertsAccess,
  alertsData,
  allCustomersTopicsData,
  employeeRecognitionData,
  featuredQuestions,
  filterEmployeeRecognitionQuestion,
  filterPocOptions,
  filterPurchaserQuestion,
  filters,
  filtersQueryString,
  generalData,
  nonPurchasersTopicsData,
  survey,
  who,
}) => {
  const filterLocationNames = filterPocOptions.find((option) => option.code === "_name");
  const filterLocationName =
    filterLocationNames && filterLocationNames.options.length === 1
      ? filterLocationNames.options[0]
      : null;

  return (
    <div className={cx("dashboard")}>
      <Header
        allCustomersTopicsData={allCustomersTopicsData}
        filterLocationName={filterLocationName}
        filterPurchaserQuestion={filterPurchaserQuestion}
        filters={filters}
        generalData={generalData}
        survey={survey}
        who={who}
      />
      <div className={cx("tiles")}>
        {alertsData === null || generalData === null ? (
          <ErrorStatus />
        ) : (
          <>
            {survey?.is_google_review ? (
              <GoogleReviewsTile
                googleReviewData={generalData?.googleReviewData}
                filters={filters}
              />
            ) : (
              <>
                {generalData?.npsData && <NpsTile npsData={generalData.npsData} filters={filters} />}
                {generalData?.satData && <SatTile satData={generalData.satData} filters={filters} />}
              </>
            )}
            {survey?.display_save_our_sales_data && <SaveOurSalesTile saveOurSalesData={generalData?.saveOurSalesData} filters={filters} />}
            <InitiationsTile
              initiationsData={generalData && generalData.initiationsData}
              filters={filters}
            />
            <AlertsTile
              alertsData={alertsData}
              filters={filters}
              link={alertsAccess && "/alerts"}
            />
          </>
        )}
      </div>
      {featuredQuestions?.length > 0 && (
        <FeaturedQuestion
          featuredQuestions={featuredQuestions}
          filtersQueryString={filtersQueryString}
          survey={survey}
        />
      )}
      <div className={cx("insights")}>
        <div className={cx("insights-title")}>{I18n.t("dashboard_lite.insight_title")}</div>
        <div className={cx("insights-container")}>
          {allCustomersTopicsData === null ||
          employeeRecognitionData === null ||
          nonPurchasersTopicsData === null ? (
            <ErrorStatus />
          ) : (
            <>
              {filterPurchaserQuestion && (
                <Insight
                  filters={filters}
                  iconFontAwesomeClass="fas fa-search-dollar"
                  isTopic={true}
                  state="negative"
                  survey={survey}
                  title={I18n.t("dashboard_lite.insight_non_purchaser")}
                  tooltip={I18n.t("dashboard_lite.insight_non_purchaser_tooltip")}
                  insightData={
                    nonPurchasersTopicsData && nonPurchasersTopicsData.mostNegativeMentions
                  }
                />
              )}
              {filterEmployeeRecognitionQuestion && (
                <Insight
                  capitalizeTopicName={true}
                  filters={filters}
                  iconFontAwesomeClass="fas fa-medal"
                  isTopic={false}
                  state="positive"
                  survey={survey}
                  title={I18n.t("dashboard_lite.insight_employees_recognition")}
                  tooltip={I18n.t("dashboard_lite.insight_employees_recognition_tooltip")}
                  insightData={employeeRecognitionData}
                />
              )}
              <Insight
                className={cx("capitalize")}
                filters={filters}
                iconFontAwesomeClass="fas fa-hands-helping"
                isTopic={true}
                state="negative"
                survey={survey}
                title={
                  filterLocationName
                    ? I18n.t("dashboard_lite.insight_suggestions_with_actionable_topics")
                    : I18n.t("dashboard_lite.insight_suggestions")
                }
                tooltip={
                  filterLocationName
                    ? I18n.t("dashboard_lite.insight_suggestions_with_actionable_topics_tooltip")
                    : I18n.t("dashboard_lite.insight_suggestions_tooltip")
                }
                insightData={allCustomersTopicsData && allCustomersTopicsData.mostNegativeMentions}
              />
              <Insight
                filters={filters}
                iconFontAwesomeClass="fas fa-thumbs-up"
                isTopic={true}
                state="positive"
                survey={survey}
                title={
                  filterLocationName
                    ? I18n.t("dashboard_lite.insight_strengths_with_actionable_topics")
                    : I18n.t("dashboard_lite.insight_strengths")
                }
                tooltip={
                  filterLocationName
                    ? I18n.t("dashboard_lite.insight_strengths_with_actionable_topics_tooltip")
                    : I18n.t("dashboard_lite.insight_strengths_tooltip")
                }
                insightData={allCustomersTopicsData && allCustomersTopicsData.mostPositiveMentions}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
